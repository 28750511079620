import { onError } from '@apollo/client/link/error';
import { ApolloClient, ApolloLink, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { InMemoryCache } from '@apollo/client/cache';
import { getAuthenticationHeader, sleep, trackExpiredSession } from '@uptime/shared/utils/general';

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (!import.meta.env.VITE_DEBUG) return false;
  trackExpiredSession(networkError);

  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}.`)
    );

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const httpPaymentLink = createHttpLink({
  uri: import.meta.env.VITE_GRAPHQL_PAYMENT,
});

const filesLink = createHttpLink({
  uri: import.meta.env.VITE_GRAPHQL_FILE,
});

const devicesLink = createHttpLink({
  uri: import.meta.env.VITE_GRAPHQL_DEVICE,
});

const newDevicesLink = createHttpLink({
  uri: import.meta.env.VITE_GRAPHQL_NEW_DEVICE,
});

const userLink = createHttpLink({
  uri: import.meta.env.VITE_GRAPHQL_USER,
});

const facilityLink = createHttpLink({
  uri: import.meta.env.VITE_GRAPHQL_FACILITY,
});

const goCheckItLink = createHttpLink({
  uri: import.meta.env.VITE_GRAPHQL_GOCHECKIT,
});

const marketplaceLink = createHttpLink({
  uri: import.meta.env.VITE_GRAPHQL_MARKETPLACE,
});

const flowLink = createHttpLink({
  uri: import.meta.env.VITE_GRAPHQL_FLOW,
});

const iotLink = createHttpLink({
  uri: import.meta.env.VITE_GRAPHQL_IOT,
});

const authLink = setContext(async (GraphQLRequest, { headers }) => {
  const accessHeader = getAuthenticationHeader();

  return {
    headers: {
      ...headers,
      ...accessHeader,
    },
  };
});

const cache = new InMemoryCache();
const cacheNoTypeName = new InMemoryCache({ addTypename: false });

export const userClient = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink.concat(userLink)]),
  fetchOptions: {
    mode: 'no-cors',
  },
  cache: cacheNoTypeName,
});

export const paymentClient = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink.concat(httpPaymentLink)]),
  fetchOptions: {
    mode: 'no-cors',
  },
  cache: cache,
});

export const filesClient = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink.concat(filesLink)]),
  cache: cacheNoTypeName,
});

export const devicesClient = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink.concat(devicesLink)]),
  cache: cacheNoTypeName,
});

export const newDeviceClient = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink.concat(newDevicesLink)]),
  cache: cache,
});

export const facilityClient = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink.concat(facilityLink)]),
  fetchOptions: {
    mode: 'no-cors',
  },
  cache: cache,
});

export const goCheckItClient = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink.concat(goCheckItLink)]),
  cache: cache,
});

export const marketplaceClient = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink.concat(marketplaceLink)]),
  cache: cache,
});

export const flowClient = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink.concat(flowLink)]),
  cache: cache,
});

export const iotClient = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink.concat(iotLink)]),
  cache: cache,
});

export const resetGraphQLCache = () => {
  // reset clients with "new InMemoryCache({ addTypename: false })"
  userClient.resetStore();
  // reset clients with "new InMemoryCache();
  facilityClient.resetStore();
};
